import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./index.css";
import icecream_banner_blur from "./assets/images/home_banner/bluricecreambanner.webp";
import brownie_banner_blur from "./assets/images/home_banner/brownie_banner_blur.webp";
import shake_banner_blur from "./assets/images/home_banner/shake_banner_blur.webp";
import shrikhand_banner_blur from "./assets/images/home_banner/shrikhand_banner_blur.webp";
import conecandy_banner_blur from "./assets/images/home_banner/conecandy_banner_blur.webp";
import sundae_banner_blur from "./assets/images/home_banner/sundae_banner_blur.webp";
import icecream_banner from "./assets/images/home_banner/icecream_banner.webp";
import shrikhand_banner from "./assets/images/home_banner/shrikhand_banner.webp";
import brownie_banner from "./assets/images/home_banner/brownie_banner.webp";
import conecandy_banner from "./assets/images/home_banner/conecandy_banner.webp";
import sundae_banner from "./assets/images/home_banner/sundae_banner.webp";
import momshake_banner from "./assets/images/home_banner/shake_banner.webp";

const preloadImages = (imageUrls) => {
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

const AppWrapper = () => {
  useEffect(() => {
    preloadImages([
      icecream_banner_blur, brownie_banner_blur, shake_banner_blur,
      shrikhand_banner_blur, conecandy_banner_blur, sundae_banner_blur,
      icecream_banner, shrikhand_banner, conecandy_banner, brownie_banner,
      sundae_banner, momshake_banner
    ]);
  }, []);

  return (
    <React.StrictMode>
      <Router basename={"/"}>
        <App />
      </Router>
    </React.StrictMode>
  );
};

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<AppWrapper />);
}
