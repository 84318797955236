import React, { useEffect, useState, useCallback, useRef } from "react";
import { GoogleMap, useLoadScript, Marker, MarkerF } from "@react-google-maps/api";

const libraries = ["places"];

const GoogleMapComponent = ({
  latitude,
  longitude,
  onSelectLocation,
  height = "100vh", // Default to '100vh' if height prop is not provided
  initialMarkers = "", // Prop to receive an array of initial markers
}) => {
  const [selectedPlace, setSelectedPlace] = useState({
    lat: 21.5354771,
    lng: 70.45433369999999,
  });
  const [markers, setMarkers] = useState([
    {
      lat: 23.022505,
      lng: 72.5713621,
    },
    {
      lat: 22.3038945,
      lng: 70.80215989999999,
    },
    {
      lat: 21.7398165,
      lng: 70.4491417,
    },
    {
      lat: 21.7546665,
      lng: 70.6179777,
    },
    {
      lat: 21.9611708,
      lng: 70.7938777,
    },
    {
      lat: 21.7395387,
      lng: 70.2795033,
    },
    {
      lat: 20.9158979,
      lng: 70.3628516,
    },
    {
      lat: 22.8251874,
      lng: 70.84908089999999,
    },
    {
      lat: 21.1702401,
      lng: 72.83106070000001,
    },
    {
      lat: 22.3071588,
      lng: 73.1812187,
    },
    {
      lat: 21.6416979,
      lng: 69.62930589999999,
    },
    {
      lat: 21.6015242,
      lng: 71.2203555,
    },
    {
      lat: 21.5222203,
      lng: 70.45794359999999,
    },
    {
      lat: 20.8009246,
      lng: 70.6960306,
    },
    {
      lat: 23.2156354,
      lng: 72.63694149999999,
    },
    {
      lat: 20.8235,
      lng: 71.0409,
    },
    {
      lat: 22.5645,
      lng: 72.9289,
    },
  ]); // Store multiple markers
  const markerRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    if (initialMarkers && initialMarkers.length > 0) {
      setMarkers(initialMarkers);
      // Zoom the map to the markers
      const bounds = new window.google.maps.LatLngBounds();
      initialMarkers.forEach((marker) => {
        bounds.extend(marker); // Extend the bounds to include each marker
      });
      if (mapRef.current) {
        mapRef.current.fitBounds(bounds); // Adjust the map to fit the bounds
      }
    }
  }, [initialMarkers]);

  useEffect(() => {
    if (latitude && longitude) {
      const newLocation = { lat: latitude, lng: longitude };
      setSelectedPlace(newLocation);
      if (mapRef.current) {
        mapRef.current.panTo(newLocation);
      }
    }
  }, [latitude, longitude]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY, // Add your API key here
    libraries,
  });

  const onMapClick = useCallback((event) => {
    const newMarker = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };

    setSelectedPlace(newMarker);
    if (mapRef.current) {
      mapRef.current.panTo(newMarker);
    }
    animateMarker();
    reverseGeocode(newMarker, newMarker);
  }, []);

  const animateMarker = () => {
    if (markerRef.current) {
      markerRef.current.setAnimation(window.google.maps.Animation.BOUNCE);
      setTimeout(() => {
        markerRef.current.setAnimation(null);
      }, 500); // Bounce animation duration
    }
  };

  const extractLocationDetails = (result) => {
    const addressComponents = result.address_components;
    const locationDetails = {
      location: result.formatted_address,
      state: getAddressComponent(
        addressComponents,
        "administrative_area_level_1"
      ),
      city: getAddressComponent(addressComponents, "locality"),
      country: getAddressComponent(addressComponents, "country"),
      latitude: result.geometry.location.lat(),
      longitude: result.geometry.location.lng(),
    };
    return locationDetails;
  };

  const reverseGeocode = async (marker) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: marker }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          // Extract location details
          const locationDetails = extractLocationDetails(results[0]);
          // Pass location details back to the parent component
          onSelectLocation(locationDetails);
        } else {
          console.error("No results found");
        }
      } else {
        console.error("Geocoder failed due to: " + status);
      }
    });
  };

  const getAddressComponent = (addressComponents, type) => {
    const component = addressComponents.find((component) =>
      component.types.includes(type)
    );
    return component ? component.long_name : "";
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }


  return (
    <GoogleMap
      mapContainerStyle={{ width: "100%", height }} // Apply dynamic height
      zoom={6}
      center={selectedPlace}
      onClick={onMapClick}
      mapContainerClassName="w-100 shadow-sm rounded-4"
      onLoad={(map) => (mapRef.current = map)}
    >
      {markers.map((marker, index) => {        
        return (
          <MarkerF
            key={index}
            position={marker}
            onLoad={(marker) => {
              if (!markerRef.current) {
                markerRef.current = marker; // Store only the first marker
              }
            }}
          />
        );
      })}

      {selectedPlace.lng != "70.45433369999999" &&
      selectedPlace.lat != "21.5354771" ? (
        <Marker position={selectedPlace} />
      ) : (
        ""
      )}
    </GoogleMap>
  );
};

export default GoogleMapComponent;
