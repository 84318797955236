import React, { useState, useEffect } from "react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import { useSwipeable } from "react-swipeable";
import { useLocation } from "react-router";

import category1 from "../assets/images/discovery/image1.svg";
import category2 from "../assets/images/discovery/image2.svg";
import category3 from "../assets/images/discovery/image3.svg";
import category4 from "../assets/images/discovery/image4.svg";
import category5 from "../assets/images/discovery/image5.svg";
import category6 from "../assets/images/discovery/image6.svg";

import category1_red from "../assets/images/discovery/image1_red.svg";
import category2_red from "../assets/images/discovery/image2_red.svg";
import category3_red from "../assets/images/discovery/image3_red.svg";
import category4_red from "../assets/images/discovery/image4_red.svg";
import category5_red from "../assets/images/discovery/image5_red.svg";
import category6_red from "../assets/images/discovery/image6_red.svg";

import image1 from "../assets/images/discovery/image1.png";
import image2 from "../assets/images/discovery/image2.png";
import image3 from "../assets/images/discovery/image3.png";
import image4 from "../assets/images/discovery/image4.png";
import image5 from "../assets/images/discovery/image5.png";
import image6 from "../assets/images/discovery/image6.png";
import image7 from "../assets/images/discovery/image7.png";
import image8 from "../assets/images/discovery/image8.png";
import image9 from "../assets/images/discovery/image9.png";
import image10 from "../assets/images/discovery/image10.png";
import image11 from "../assets/images/discovery/image11.png";
import image12 from "../assets/images/discovery/image12.png";
import image13 from "../assets/images/discovery/image13.png";
import image14 from "../assets/images/discovery/image14.png";
import image15 from "../assets/images/discovery/image15.png";
import image16 from "../assets/images/discovery/image16.png";
import image17 from "../assets/images/discovery/image17.png";
import image18 from "../assets/images/discovery/image18.png";
import image19 from "../assets/images/discovery/image19.png";
import image20 from "../assets/images/discovery/image20.png";
import image21 from "../assets/images/discovery/image21.png";
import image22 from "../assets/images/discovery/image22.png";
import image23 from "../assets/images/discovery/image23.png";
import image24 from "../assets/images/discovery/image24.png";
import image25 from "../assets/images/discovery/image25.png";
import image26 from "../assets/images/discovery/image26.png";
import image27 from "../assets/images/discovery/image27.png";
import image28 from "../assets/images/discovery/image28.png";
import image29 from "../assets/images/discovery/image29.png";
import image30 from "../assets/images/discovery/image30.png";
import image31 from "../assets/images/discovery/image31.png";
import image32 from "../assets/images/discovery/image32.png";
import image33 from "../assets/images/discovery/image33.png";
import image34 from "../assets/images/discovery/image34.png";
import image35 from "../assets/images/discovery/image35.png";
import image36 from "../assets/images/discovery/image36.png";
import image37 from "../assets/images/discovery/image37.png";
import image38 from "../assets/images/discovery/image38.png";
import image39 from "../assets/images/discovery/image39.png";
import image40 from "../assets/images/discovery/image40.png";
import image41 from "../assets/images/discovery/image41.png";
import image42 from "../assets/images/discovery/image42.png";
import image43 from "../assets/images/discovery/image43.png";
import image44 from "../assets/images/discovery/image44.png";
import image45 from "../assets/images/discovery/image45.png";
import image46 from "../assets/images/discovery/image46.png";
import image47 from "../assets/images/discovery/image47.png";
import image48 from "../assets/images/discovery/image48.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const DeliciousDiscoveryWrapper = () => {
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [redImage, setRedImage] = useState("");
  const [displayedImages, setDisplayedImages] = useState([]); // State to store displayed images
  const [isCodeVisible, setIsCodeVisible] = useState(false);
  const categoryId = location.state?.categoryId || "";

  const categories = [
    {
      id: "1",
      images: category1,
      images_red: category1_red,
      title: "Ice Cream",
    },
    {
      id: "2",
      images: category2,
      images_red: category2_red,
      title: "Sundae",
    },
    {
      id: "3",
      images: category3,
      images_red: category3_red,

      title: "M.O.M Shake",
    },
    {
      id: "4",
      images: category4,
      images_red: category4_red,

      title: "Ice Cream Shrikhand",
    },
    {
      id: "5",
      images: category5,
      images_red: category5_red,

      title: "Sizzling Brownie",
    },

    {
      id: "6",
      images: category6,
      images_red: category6_red,

      title: "Stick Bar",
    },
  ];
  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
  });

  const subCategory = [
    { subCategoryId: "1", categoryId: "1", title: "Ice Cream" },

    { subCategoryId: "2", categoryId: "2", title: "Sundae" },

    { subCategoryId: "3", categoryId: "3", title: "M.O.M Shake" },
    // { subCategoryId: "4", categoryId: "3", title: "Signature Shake" },

    { subCategoryId: "5", categoryId: "4", title: "Ice Cream Shrikhand" },

    { subCategoryId: "6", categoryId: "5", title: "Sizzling Brownie" },

    { subCategoryId: "7", categoryId: "6", title: "Stick Bar" },
  ];

  const filteredSubCategories = subCategory.filter(
    (item) => item.categoryId == selectedCategory
  );

  const subCategoryImage = [
    {
      subCategoryId: "1",
      images: image1,
      title: "American Dryfruit",
    
    },
    {
      subCategoryId: "1",
      images: image2,
      title: "Choco Brownie",
    },
    {
      subCategoryId: "1",
      images: image3,
      title: "Choco Munch",
    },
    {
      subCategoryId: "1",
      images: image4,
      title: "Coconut",
    },
    {
      subCategoryId: "1",
      images: image5,
      title: "Jamfal",
    },
    {
      subCategoryId: "1",
      images: image6,
      title: "Kesar Pista",
    },
    {
      subCategoryId: "1",
      images: image7,
      title: "Kit Kat",
    },
    {
      subCategoryId: "1",
      images: image8,
      title: "Mango",
    },
    {
      subCategoryId: "1",
      images: image9,
      title: "RajBhog",
    },
    {
      subCategoryId: "1",
      images: image10,
      title: "Red Velvet",
    },
    {
      subCategoryId: "1",
      images: image11,
      title: "Roasted Nuts",
    },
    {
      subCategoryId: "1",
      images: image12,
      title: "Silk Chocolate",
    },
    {
      subCategoryId: "1",
      images: image13,
      title: "Sitafal",
    },
    {
      subCategoryId: "1",
      images: image14,
      title: "Sp. Marwad",
    },
    {
      subCategoryId: "1",
      images: image15,
      title: "Sugar Free (Rajbhog)",
    },
    {
      subCategoryId: "1",
      images: image16,
      title: "Vanilla",
    },
    {
      subCategoryId: "2",
      images: image17,
      title: "American Sundae",
    },
    {
      subCategoryId: "2",
      images: image18,
      title: "Choco Crunch Sundae",
    },
    {
      subCategoryId: "2",
      images: image19,
      title: "Dryfruit Khajana Sundae",
    },
    {
      subCategoryId: "2",
      images: image20,
      title: "Kit Kat Sundae",
    },
    {
      subCategoryId: "2",
      images: image21,
      title: "Pina Coconut Sundae",
    },
    {
      subCategoryId: "2",
      images: image22,
      title: "Red Velvet Sundae",
    },
    {
      subCategoryId: "2",
      images: image23,
      title: "Roasted Dryfruit Sundae",
    },
    {
      subCategoryId: "3",
      images: image24,
      title: "Double Badam Shake",
    },
    {
      subCategoryId: "3",
      images: image25,
      title: "Kaju Anjeer Shake",
    },
    {
      subCategoryId: "3",
      images: image26,
      title: "Kesar Pista Shake",
    },
    {
      subCategoryId: "3",
      images: image27,
      title: "Rangoli Sp Shake Extended New",
    },
    {
      subCategoryId: "3",
      images: image28,
      title: "Rangoli Sp Shake",
    },

    {
      subCategoryId: "3",
      images: image29,
      title: "Roasted Dryfruits Shake",
    },

    {
      subCategoryId: "3",
      images: image30,
      title: "Sp. Marwad Shake",
    },
    {
      subCategoryId: "3",
      images: image31,
      title: "Vanilla Shake",
    },
    {
      subCategoryId: "3",
      images: image32,
      title: "Butterscotch Shake",
    },
    {
      subCategoryId: "3",
      images: image33,
      title: "Cadbury Shake",
    },
    {
      subCategoryId: "3",
      images: image34,
      title: "Choco Brownie Shake",
    },
    {
      subCategoryId: "3",
      images: image35,
      title: "Kaju Gulkand New",
    },
    {
      subCategoryId: "3",
      images: image36,
      title: "Kit-Kat Shake",
    },
    {
      subCategoryId: "3",
      images: image37,
      title: "Nutty Choco Shake",
    },
    {
      subCategoryId: "3",
      images: image38,
      title: "Oreo Shake",
    },
    {
      subCategoryId: "3",
      images: image39,
      title: "Red Velvet Shake",
    },
    {
      subCategoryId: "3",
      images: image40,
      title: "Strawberry Cream Shake",
    },
    {
      subCategoryId: "5",
      images: image41,
      title: "3 Shrikhand Combo",
    },
    {
      subCategoryId: "5",
      images: image42,
      title: "Bon Bon",
    },
    {
      subCategoryId: "6",
      images: image43,
      title: "Brownie",
    },
    {
      subCategoryId: "7",
      images: image44,
      title: "Biscoff Cone",
    },
    {
      subCategoryId: "7",
      images: image45,
      title: "Cassata",
    },
    {
      subCategoryId: "7",
      images: image46,
      title: "Center Burst Candy",
    },
    {
      subCategoryId: "7",
      images: image47,
      title: "Natural Jamun Candy",
    },
    {
      subCategoryId: "7",
      images: image48,
      title: "Red Velvet Cone",
    },
  ];

  // to give the list of the subvcategories
  useEffect(() => {
    if (filteredSubCategories.length > 0) {
      setSelectedSubCategory(filteredSubCategories[0].subCategoryId);
    } else {
      setSelectedSubCategory(""); // Clear subcategory if none found
    }
  }, [selectedCategory]);

  // to set the displayed image when the sub category is changed
  useEffect(() => {
    if (selectedSubCategory) {
      const imagesForSubCategory = subCategoryImage.filter(
        (item) => item.subCategoryId == selectedSubCategory
      );
      setDisplayedImages(imagesForSubCategory);
    } else {
      setDisplayedImages([]);
    }
  }, [selectedSubCategory]);

  // Animation variants for the subcategory images
  const variants = {
    hidden: { opacity: 0, y: -50 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.2, type: "spring", stiffness: 50 },
    }),
  };

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const variants3 = {
    hidden: { opacity: 0, scale: 0.8 }, // Start scaled down
    visible: (i) => ({
      opacity: 1,
      scale: 1, // Scale to original size
      transition: {
        type: "spring",
        stiffness: 50,
        opacity: { duration: 0.5 }, // Fade-in duration
        scale: { type: "spring", stiffness: 50, damping: 10 }, // Scale-in duration and bounce effect
      },
    }),
    exit: {
      opacity: 0,
      scale: 0.8, // Scale down when exiting
      transition: {
        opacity: { duration: 0.5 }, // Fade-out duration
        scale: { type: "spring", stiffness: 50, damping: 10 }, // Scale-out duration and bounce effect
      },
    },
  };

  useEffect(() => {
    if (categoryId != "") {
      setSelectedCategory(categoryId);
      const categoryIndex = categories.findIndex(
        (item) => item.id === categoryId
      );
      setCurrentIndex(
        Math.max(0, Math.min(categoryIndex, categories.length - imagesPerSlide))
      );
      const images = categories[categoryIndex];
      setRedImage(images.images_red);
      setIsCodeVisible(true);
    } else {
      setSelectedCategory("1");
      setRedImage(categories[0].images_red);
      setIsCodeVisible(true);
    }
  }, []);

  const [width, setWidth] = useState(calculateWidth(window.innerWidth));
  var imagesPerSlide = width;

  useEffect(() => {
    const handleResize = () => {
      setWidth(calculateWidth(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function calculateWidth(width) {
    if (width > 350 && width < 500) {
      return 3;
    } else if (width < 350) {
      return 1;
    } else {
      return 3;
    }
  }

  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);

  const prevSlide = () => {
    setDirection(-1);

    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? categories.length - imagesPerSlide : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setDirection(1);

    setCurrentIndex((prevIndex) =>
      prevIndex >= categories.length - imagesPerSlide ? 0 : prevIndex + 1
    );
  };

  const currentImages = categories.slice(
    currentIndex,
    currentIndex + imagesPerSlide
  );

  
    useEffect(()=>{
      document.title = 'Rangoli Ice Cream || Delicious Discovery'; 
    },[])
    

  return (
    <>
      <motion.div className="d-flex justify-content-center main_title_news wow animate__animated animate__fadeInDown">
        <h1 className="text-center new_arrival_title">Delicious Discovery</h1>
      </motion.div>

      <div {...handlers} className="category_container category_slider">
        <div className="category_carousel">
          <AnimatePresence initial={false} custom={direction}>
            {currentImages.map((item, index) => {
              const isSelected = selectedCategory === item.id;
              return (
                <motion.div
                  custom={index}
                  className={`text-center wow animate__animated animate__fadeInDown ${isSelected ? "selected" : ""}`}
                  onClick={() => {
                    setSelectedCategory(item.id);
                    setIsCodeVisible(true);
                    setRedImage(item.images_red);
                  }}
                  style={{
                    borderRadius: "8px",
                    width: "14rem",
                    paddingTop: "1rem",
                  }}
                >
                  {isSelected & isCodeVisible ? (
                    <motion.div
                      custom={index}
                      initial="hidden"
                      animate="visible"
                      variants={variants3}
                    >
                      <img
                        src={redImage}
                        alt="category"
                        className="category_image"
                        style={{
                          width: screenSize < 425 ? "2.8rem" : "",
                          height: screenSize < 425 ? "2.8rem" : "",
                        }}
                      />
                    </motion.div>
                  ) : (
                    <img
                      src={item.images}
                      alt="category"
                      className="category_image"
                      style={{
                        width: screenSize < 425 ? "2.8rem" : "",
                        height: screenSize < 425 ? "2.8rem" : "",
                      }}
                    />
                  )}
                  {isSelected & isCodeVisible ? (
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      variants={variants3}
                      style={{
                        color: "#e32024",
                        fontSize: screenSize < 425 ? "18px" : "",
                      }}
                      className="category_title"
                    >
                      {item.title}
                    </motion.div>
                  ) : (
                    <div
                      className="category_title"
                      style={{
                        fontSize: screenSize < 425 ? "18px" : "",
                      }}
                    >
                      {item.title}
                    </div>
                  )}
                </motion.div>
              );
            })}
          </AnimatePresence>
        </div>
      </div>
      <div className="category_slider_buttons category_slider">
        <button
          className="category_slider_button next_button_category"
          onClick={nextSlide}
        >
          <FontAwesomeIcon
            icon={faGreaterThan}
            style={{
              transform: "rotate(180deg)", // Rotates the icon 180 degrees
              fontSize: "12px", // Adjust the size to make it smaller
            }}
          />
        </button>
        <button
          className="category_slider_button prev_button_category"
          onClick={prevSlide}
        >
          <FontAwesomeIcon icon={faGreaterThan}></FontAwesomeIcon>
        </button>
      </div>

      <div className="tab_section delicious_page_menu">
        {categories.map((item, index) => {
          const isSelected = selectedCategory === item.id;

          return (
            <AnimatePresence>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                custom={index}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                variants={variants}
                key={item.title}
                className={`text-center ${
                  isSelected ? "selected" : ""
                } cursor-pointer`}
                onClick={() => {
                  setSelectedCategory(item.id);
                  setIsCodeVisible(true);
                  setDisplayedImages([]);
                  setRedImage(item.images_red);
                }}
                style={{
                  borderRadius: "8px",
                  width: "14rem",
                  paddingTop: "1rem",
                }}
              >
                {isSelected & isCodeVisible ? (
                  <motion.div
                    custom={index}
                    initial="hidden"
                    animate="visible"
                    variants={variants3}
                  >
                    <img
                      src={redImage}
                      alt={`${item.title} Rangoli Ice Cream`}
                      className="category_image"
                    />
                  </motion.div>
                ) : (
                  <img
                    src={item.images}
                    
                    alt={`${item.title} image Rangoli Ice Cream`}
                    className="category_image"
                  />
                )}
                {isSelected & isCodeVisible ? (
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={variants3}
                    style={{ color: "#e32024" }}
                    className="category_title"
                  >
                    {item.title}
                  </motion.div>
                ) : (
                  <div className="category_title">{item.title}</div>
                )}
              </motion.div>
            </AnimatePresence>
          );
        })}
      </div>

      <div style={{ border: "1px solid #D0D0D0" }} className="m-5"></div>

      <div className="subCategory_images">
        {displayedImages.length > 0 &&
          displayedImages.map((items, index) => (
            <motion.div
              key={index}
              custom={index}
              initial="hidden"
              animate="visible"
              variants={variants}
              className="mt-5"
            >
              <LazyLoadImage
                src={items.images}
                alt={`${items.title} Image Rangoli Ice Cream`}
                style={{
                  borderRadius: "50px",
                  width: "333px",
                  height: "400px",
                }}
              />
              <div className="subcategory_image_title">{items.title}</div>
            </motion.div>
          ))}
      </div>

      <Footer />
    </>
  );
};

const DeliciousDiscovery = () => {
  return <Header element={<DeliciousDiscoveryWrapper />} />;
};

export { DeliciousDiscovery };
