import React from "react";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import rangoli_logo from "../../assets/images/rangoli_logo.png";
import rangoli_logo2 from "../../assets/images/rangoli_logo.svg";
import callImage from "../../assets/images/footer/call.png"
import smsImage from "../../assets/images/footer/sms.png"
import xpertlabLogo from "../../assets/images/xpertlablogo.png"
import facebookLogo from "../../assets/images/footer/facebook.svg"
import instagramLogo from "../../assets/images/footer/instagram.svg"
import youtubeLogo from "../../assets/images/footer/youtube.svg"
import twitterlogo from "../../assets/images/footer/twitter.svg"
import { osName } from "react-device-detect";

const Footer = () => {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `../../`;
    navigate(path);
  };

  const categories = [
    { title: "Ice-Cream", categoryId: "1" },
    { title: "Sundae", categoryId: "2" },
    { title: "M.O.M Shake", categoryId: "3" },
    { title: "Ice Cream Shrikhand", categoryId: "4" },
    { title: "Sizzling Brownie", categoryId: "5" },
    { title: "Stick Bar", categoryId: "6" },
  ];
  const menus = [
    { title: "Home", path: "../" },
    { title: "About Us", path: "../aboutus" },
    { title: "Franchise Inquiry", path: "../franchiseinquiry" },
    {
      title: "Delicious Discovery",
      path: "../deliciousdiscovery",
    },
    { title: "Career", path: "../career" },
    {
      title: "Magical Moments",
      path: "../magicalmoments",
    },
    { title: "Contact Us", path: "../contactus" },
  ];

  const variants3 = {
    hidden: { opacity: 0, scale: 0.8 }, // Start scaled down
    visible: (i) => ({
      opacity: 1,
      scale: 1, // Scale to original size
      transition: {
        type: "spring",
        stiffness: 50,
        opacity: { duration: 0.5 }, // Fade-in duration
        scale: { type: "spring", stiffness: 50, damping: 10 }, // Scale-in duration and bounce effect
      },
    }),
    exit: {
      opacity: 0,
      scale: 0.8, // Scale down when exiting
      transition: {
        opacity: { duration: 0.5 }, // Fade-out duration
        scale: { type: "spring", stiffness: 50, damping: 10 }, // Scale-out duration and bounce effect
      },
    },
  };

  const iconVariants = {
    hidden: { opacity: 0, y: 20, scale: 0.8 }, // Initial state: hidden, move down slightly
    visible: (i) => ({
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        delay: i * 0.2, // Stagger animation for each icon
        duration: 0.6,
        type: "spring",
        stiffness: 100,
      },
    }),
  };

  return (
    <>
      <div className="footer_section py-0" style={{ position: "relative" }}>


        <div className="footer_banner bigfooterbackground"></div>

        <div
          className="arrival_content pt-5"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: "10000",
          }}
        >
          <div className="detail_section_footer container">
            <div className="row">
              <div className="col-lg-3 col-sm-12 footer_logo_image  col-md-3">
                <motion.div
                  whileTap={{ scale: 0.8 }}
                  className="cursor-pointer"
                >
                  <Link to="../">
                    <motion.img
                      whileHover={{ scale: 1.09, originX: 0 }}
                      src={ osName =="Android" ? rangoli_logo2 : rangoli_logo}
                      onClick={routeChange}
                      alt="Rangoli Ice Cream Logo"
                      className="footer_logo"
                    />
                  </Link>
                </motion.div>
                <div className="mt-4 footer_logo_text">
                  <motion.p
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={variants3}
                  >
                    We Make People Joyful by Sharing Joy
                  </motion.p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-4 col-6 col-md-3 d-flex justify-content-center categories_links ">
                <div>
                  <p className="heading-footer">Menu</p>
                  <div className="list">
                    {menus.map((items) => {
                      return (
                        <Link
                          rel="noreferrer"
                          className="text-decoration-none"
                          to={`${items.path}`}
                        >
                          <motion.p
                            whileHover={{ color: "#E32024" }}
                            className="text-decoration-none"
                          >
                            {items.title}
                          </motion.p>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
              <motion.div className="col-lg-3 col-sm-4 col-6 col-md-3 d-flex justify-content-center categories_links">
                <div>
                  <p className="heading-footer">Categories</p>
                  <div className="list ">
                    {categories.map((items) => {
                      return (
                        <Link
                          to={"/deliciousdiscovery"}
                          className="text-decoration-none "
                          state={{ categoryId: items.categoryId }}
                        >
                          <motion.p
                            whileHover={{ color: "#E32024" }}
                            className="text-decoration-none"
                          >
                            {items.title}
                          </motion.p>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </motion.div>
              <div className="col-lg-3 col-sm-4 col-12 col-md-3 d-flex justify-content-center contact_section ">
                <div>
                  <div className="list">
                    <motion.p
                      whileHover={{ color: "#E32024" }}
                      transition={{ type: "spring" }}
                      className="cursor-pointer"
                    >
                      <motion.a
                        whileHover={{ color: "#E32024" }}
                        rel="noreferrer"
                        href="tel:+91 95122 31112"
                        className="text-decoration-none"
                      >
                        <img
                          src={callImage}
                          alt="Call Icon"
                        />
                      </motion.a>

                      <motion.a
                        whileHover={{ color: "#E32024" }}
                        rel="noreferrer"
                        href="tel:+91 95122 31112"
                        className="text-decoration-none cursor-pointer "
                        style={{ marginLeft: "0.7rem" }}
                      >
                        95122 31112{" "}
                      </motion.a>
                    </motion.p>

                    <motion.p
                      whileHover={{ color: "#E32024" }}
                      transition={{ type: "spring" }}
                      className="text-decoration-none"
                    >
                      <motion.a
                        rel="noreferrer"
                        href="mailto:info@rangoliicecream.com"
                        className="text-decoration-none"
                      >
                        <img
                          src={smsImage}
                          alt="Email Icon"
                        />
                      </motion.a>

                      <motion.a
                        whileHover={{ color: "#E32024" }}
                        rel="noreferrer"
                        href="mailto:info@rangoliicecream.com"
                        className="text-decoration-none"
                        style={{ marginLeft: "0.7rem" }}
                      >
                        rangoliicecream.com
                      </motion.a>
                    </motion.p>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ border: "1px solid #E2D6BD", marginTop: "2rem" }}
            ></div>

            <div className="container footer_links">
              <div className="footer_links_inner mt-5">
                <div className="xpert_link">
                  © {new Date().getFullYear()}
                  <span style={{ color: "#F00540" }}>
                    <Link
                      whileHover={{ color: "rgb(240, 5, 64)" }}
                      to="../"
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      {" "}
                      Rangoli
                    </Link>
                  </span>{" "}
                  All Rights Reserved.
                </div>
                <div className="xpert_link xpert_link2">
                  Designed & Developed By
                  <span>
                    <a
                      href="https://xpertlab.com/"
                      className="text-decoration-none"
                      without
                      rel="noreferrer"
                      target="_blank"
                    >
                      {" "}
                      <img
                        className="xpertlab_logo"
                        src={xpertlabLogo}
                        alt="xpertlab logo"
                      />
                    </a>
                  </span>
                </div>
                <div className="footer_links2 d-flex gap-5">
                  {[
                    facebookLogo,
                    instagramLogo,
                    twitterlogo,
                    youtubeLogo,
                  ].map((icon, index) => (
                    <motion.div
                      key={icon}
                      custom={index} // Pass index to stagger the animation
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }} // Animate only once when in view
                      variants={iconVariants}
                    >
                      <Link
                        to={
                          icon == instagramLogo
                            ? "https://www.instagram.com/rangoliicecream.official?igsh=ZnIyMnNoeGdkcWhw"
                            : "#"
                        }
                        rel="noreferrer"
                        target="_blank"
                      >
                        <motion.img
                          src={icon}
                          alt={`${icon.split(".")[0]} logo`} // Dynamic alt text
                          whileHover={{ y: "-10px" }}
                          className="cursor-pointer"
                        />
                      </Link>
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Footer };
