import React, { useEffect, useState } from "react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import NumberCounter from "./components/NumberCounter";
import { motion } from "framer-motion";
import awardImage1 from '../assets/images/aboutsection/award1.png';
import awardImage2 from '../assets/images/aboutsection/award2.png';

const AboutWrapper = () => {
  const [counter, setCounter] = useState();
  const [direction, setDirection] = useState(-300); // Initial direction from the left
  const [isVisible, setIsVisible] = useState(false);
  const [showNewImage, setShowNewImage] = useState(false);

  const [mapVisible, setMapVisible] = useState(false);

  useEffect(() => {
    // Set a timeout to change the visibility after 3 seconds
    const timer = setTimeout(() => {
      setMapVisible(true);
    }, 200);

    // Cleanup timeout if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Set a timeout to change the visibility after 0.8 seconds
    setIsVisible(true);

    const hideTimer = setTimeout(() => {
      setIsVisible(false);
      setShowNewImage(true);
    }, 2700); // Adjust timing for smoother transition

    // Cleanup timers if the component unmounts
    return () => {
      clearTimeout(hideTimer);
    };
  }, []);

  useEffect(() => {
    if (counter === 4) {
      setDirection(300); // Switch direction to the right
    }
  }, [counter]);

  const variants2 = {
    hidden: { opacity: 0, x: 100 }, // Start from the right
    visible: (i) => ({
      opacity: 1,
      x: 0, // Move to the center
      transition: { delay: i * 0.2, type: "spring", stiffness: 50 },
    }),
  };

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const variants3 = {
    hidden: {
      opacity: 0,
      x: screenSize < 401 ? -50 : screenSize < 739 ? -100 : -200,
    }, // Start off-screen to the left
    visible: (i) => ({
      opacity: 1,
      x: 0, // Move to the original position
      transition: {
        delay: i * 0.2, // Adds a staggered delay
        type: "spring", // Use spring animation for smoothness
        stiffness: 50, // Controls how bouncy the animation is
        damping: 20, // Controls the smoothness
      },
    }),
  };

  // Define animation variants for the initial images
  const variants6 = {
    hidden: { opacity: 0, x: -200 }, // Off-screen to the left
    visible: (i) => ({
      opacity: 1,
      x:  0, // Moves to its original position
      transition: {
        delay: i * 0.2, // Adds a staggered delay
        type: "spring", // Smooth spring animation
        stiffness: 50,
        damping: 20,
      },
    }),
    exit: {
      opacity: 0, // Fade out
      scale: 0.5, // Shrink the image
      transition: {
        duration: 0.8, // Duration of fade and shrink
        ease: "easeInOut", // Smooth ease
      },
    },
  };

  const variants7 = {
    hidden: { opacity: 0, x: 200 }, // Off-screen to the right
    visible: (i) => ({
      opacity: 1,
      x:  0, // Moves to its original position
      transition: {
        delay: i * 0.2,
        type: "spring",
        stiffness: 50,
        damping: 20,
      },
    }),
    exit: {
      opacity: 0, // Fade out
      scale: 0.5, // Shrink the image
      transition: {
        duration: 0.8, // Duration of fade and shrink
        ease: "easeInOut", // Smooth ease
      },
    },
  };


  const animationVariants = {
    visible: { opacity: 1, scale: 1, y: 0 },
    hidden: { opacity: 0, scale: 0.5, y: 100 },
  };


  useEffect(() => {
    document.title = "Rangoli Ice Cream || About Us";

  }, []);

  return (
    <>
      <motion.h1
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={animationVariants} 
        className="d-flex justify-content-center main_title_news"
      >
        <p
          className="new_arrival_title text-center"
        >
          About Us
        </p>
      </motion.h1>

      <div className="career_description container text-center">
        <motion.p
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ delay: 100 }}
          variants={variants3}
        >
          Founded in 1980 by Mr. V.K. Patel in the village of Bhola, Dhoraji
          Taluka, Rangoli Ice Cream has been on a mission to sweeten the lives
          of millions with high-quality, innovative desserts. Starting with
          fruit shakes and evolving into a renowned dessert brand, Rangoli
          expanded its offerings with the launch of 'Fresh Lemon' in 1993, and
          its flagship ice cream range in 1999. Over the years, we’ve grown from
          a small village shop to a beloved brand with over 50 outlets across
          Gujarat, maintaining our promise of quality and customer satisfaction.
        </motion.p>

        <motion.p
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ delay: 100 }}
          variants={variants2}
          className="mt-2"
        >
          Our story reflects a commitment to continuous innovation, from the
          establishment of our factory in Dhoraji in 2008, producing cones,
          cups, and candies, to expanding to 10 outlets by 2016. In 2021, we
          upgraded to a state-of-the-art 50,000 square feet factory,
          underscoring our dedication to hygiene and high standards. Rangoli
          continues to win hearts, being awarded the 'Best Dessert Place' in
          Junagadh by the Rajani Group in 2019.
        </motion.p>
      </div>

      <motion.h2
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={animationVariants} // Apply the text animation
        className="d-flex justify-content-center main_title_news mt-5"
      >
        <p
          className="new_arrival_title text-center"
        >
          Mission
        </p>
      </motion.h2>

      <div className="career_description container text-center">
        <motion.p
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ delay: 100 }}
          variants={variants3}
        >
          Our mission is to deliver joy and indulgence through high-quality,
          innovative ice creams and desserts, crafted with passion and care, to
          create memorable experiences for families and communities across
          Gujarat and beyond.
        </motion.p>
      </div>

      <motion.h2
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={animationVariants} // Apply the text animation
        className="d-flex justify-content-center main_title_news"
      >
        <p
          className="new_arrival_title text-center"
        >
          Vision
        </p>
      </motion.h2>

      <div className="career_description container text-center">
        <motion.p
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ delay: 100 }}
          variants={variants3}
        >
          Our vision is to become a global symbol of sweetness, spreading
          happiness through our diverse range of desserts while maintaining the
          highest standards of quality, innovation, and customer delight. We aim
          to continue growing our footprint, preserving our essence while
          adapting to modern tastes and trends.
        </motion.p>
      </div>

      <div className="container">
        <div className="award_section">
          <motion.div
           initial="hidden"
           whileInView="visible"
           viewport={{ once: true }}
           variants={variants6}
          >
            <img
              src={awardImage1}
              alt="Rangoli Ice Cream Award Image 1"
              className="awardimage1"
            />
          </motion.div>
          
          <motion.div
           initial="hidden"
           whileInView="visible"
           viewport={{ once: true }}
           variants={variants7}
          >
            <img
              src={awardImage2}
              alt="Rangoli Ice Cream Award Image 2"
              className="awardimage2"

            />
          </motion.div>
        </div>
      </div>

      <div className="counter_section">
        
        <div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={animationVariants}
            className="counter_heading"
          >
            Branches
          </motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={variants2}
            className="counter d-flex justify-content-center"
          >
            <NumberCounter endValue={50} duration={3} />+
          </motion.div>
        </div>
        <div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={animationVariants}
            className="counter_heading"
          >
            Cities
          </motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={variants2}
            className="counter d-flex justify-content-center"
          >
            <NumberCounter endValue={20} duration={3} />+
          </motion.div>
        </div>
      </div>

      <Footer />
    </>
  );
};

const AboutUs = () => {
  return <Header element={<AboutWrapper />} />;
};

export { AboutUs };
