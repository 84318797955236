import React, { useEffect, useRef, useState } from "react";
import { toAbsoluteUrl } from "../helpers/AssetHelpers";
import { Header } from "./components/Header";
import { motion, AnimatePresence } from "framer-motion";
import Carousel from "./components/Carousel";
import Select from "react-select";
import GoogleMapComponent from "./components/GoogleMapComponent";
import { Footer } from "./components/Footer";
import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSwipeable } from "react-swipeable";
// google place api  in event address
import AnimatedText from "./components/Animatetext";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import icecream_banner_blur from "../assets/images/home_banner/bluricecreambanner.webp";
import brownie_banner_blur from "../assets/images/home_banner/brownie_banner_blur.webp";
import shake_banner_blur from "../assets/images/home_banner/shake_banner_blur.webp";
import shrikhand_banner_blur from "../assets/images/home_banner/shrikhand_banner_blur.webp";
import conecandy_banner_blur from "../assets/images/home_banner/conecandy_banner_blur.webp";
import sundae_banner_blur from "../assets/images/home_banner/sundae_banner_blur.webp";
import icecream_banner from "../assets/images/home_banner/icecream_banner.webp";
import shrikhand_banner from "../assets/images/home_banner/shrikhand_banner.webp";
import brownie_banner from "../assets/images/home_banner/brownie_banner.webp";
import conecandy_banner from "../assets/images/home_banner/conecandy_banner.webp";
import sundae_banner from "../assets/images/home_banner/sundae_banner.webp";
import momshake_banner from "../assets/images/home_banner/shake_banner.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import sideInnerRight from "../assets/images/new_arrival/sidelineright.png";
import sideInnerLeft from "../assets/images/new_arrival/sidelineleft.png";
import divider from "../assets/images/new_arrival/divider_7 3.png";

import newarrivalimage1 from "../assets/images/new_arrival/image1.webp";
import newarrivalimage3 from "../assets/images/new_arrival/image3.webp";
import newarrivalimage5 from "../assets/images/new_arrival/image5.webp";
import newarrivalimage6 from "../assets/images/new_arrival/image6.webp";
import newarrivalimage7 from "../assets/images/new_arrival/image7.webp";

import category1 from "../assets/images/arrival_section_below/image1.svg";
import category2 from "../assets/images/arrival_section_below/image2.svg";
import category3 from "../assets/images/arrival_section_below/image3.svg";
import category4 from "../assets/images/arrival_section_below/image4.svg";
import category5 from "../assets/images/arrival_section_below/image5.svg";
import category6 from "../assets/images/arrival_section_below/image6.svg";

import category1_red from "../assets/images/arrival_section_below/image1_red.svg";
import category2_red from "../assets/images/arrival_section_below/image2_red.svg";
import category3_red from "../assets/images/arrival_section_below/image3_red.svg";
import category4_red from "../assets/images/arrival_section_below/image4_red.svg";
import category5_red from "../assets/images/arrival_section_below/image5_red.svg";
import category6_red from "../assets/images/arrival_section_below/image6_red.svg";

import category1image1 from "../assets/images/arrival_section_below/icecream1.webp";
import category1image2 from "../assets/images/arrival_section_below/icecream2.webp";
import category1image3 from "../assets/images/arrival_section_below/icecream3.webp";

import category2image1 from "../assets/images/arrival_section_below/sundae1.webp";
import category2image2 from "../assets/images/arrival_section_below/sundae2.webp";
import category2image3 from "../assets/images/arrival_section_below/sundae3.webp";

import category3image1 from "../assets/images/arrival_section_below/shake1.webp";
import category3image2 from "../assets/images/arrival_section_below/shake2.webp";
import category3image3 from "../assets/images/arrival_section_below/shake3.webp";

import category4image1 from "../assets/images/arrival_section_below/shrikhand1.webp";
import category4image2 from "../assets/images/arrival_section_below/shrikhand2.webp";
import category4image3 from "../assets/images/arrival_section_below/shrikhand3.webp";

import category5image1 from "../assets/images/arrival_section_below/brownie1.webp";
import category5image2 from "../assets/images/arrival_section_below/brownie2.webp";
import category5image3 from "../assets/images/arrival_section_below/brownie3.webp";

import category6image1 from "../assets/images/arrival_section_below/stickbar1.webp";
import category6image2 from "../assets/images/arrival_section_below/stickbar2.webp";
import category6image3 from "../assets/images/arrival_section_below/stickbar3.webp";

import subcategory_background from "../assets/images/arrival_section_below/subcategorybackground.png";

import journey_image from "../assets/images/home_banner/ourjourneyimage.webp";

import anotherImage from "../assets/images/connectwithus/anotherimage.svg";

import "react-lazy-load-image-component/src/effects/blur.css";
import { toasterrormsg } from "../util/reusable";
import { apiHeader, postData } from "../util/ApiHelper";

const data = [
  {
    name: "Ice Cream",
    activeColor: "#D4A951",
    alt: "Ice Cream  Rangoli",
    backgroundImage: icecream_banner,
    blur: icecream_banner_blur,
    title: "ICE CREAM",
  },
  {
    name: "Sundae",
    activeColor: "#D86D83",
    alt: "Sundae  Rangoli Ice Cream",
    backgroundImage: sundae_banner,
    blur: sundae_banner_blur,
    title: "SUNDAE",
  },
  {
    name: "M.O.M Shake",
    activeColor: "#D4A951",
    alt: "Mom Shake  Rangoli Ice Cream",
    backgroundImage: momshake_banner,
    blur: shake_banner_blur,
    title: "M.O.M Shake",
  },

  { name: "", activeColor: "", backgroundImage: "", title: "" },
  { name: "", activeColor: "", backgroundImage: "", title: "" },
  { name: "", activeColor: "", backgroundImage: "", title: "" },
  {
    name: "Ice Cream Shrikhand",
    activeColor: "#D86D83",
    alt: "Ice Cream Shrikhand  Rangoli Ice Cream",
    backgroundImage: shrikhand_banner,
    blur: shrikhand_banner_blur,
    title: "ICE CREAM SHRIKHAND",
  },
  {
    name: "Sizzling Brownie",
    activeColor: "#D4A951",
    alt: "Sizzling Brownie = Rangoli Ice Cream",
    backgroundImage: brownie_banner,
    blur: brownie_banner_blur,
    title: "SIZZLING BROWNIE",
  },

  {
    name: "Stick Bar",
    activeColor: "#D86D83",
    alt: "Stick Bar  Rangoli Ice Cream",
    backgroundImage: conecandy_banner,
    blur: conecandy_banner_blur,
    title: "STICK BAR",
  },
];

const HomeWrapper = () => {
  const [loadedImages, setLoadedImages] = useState([]);

  const handleImageLoad = (src) => {
    setLoadedImages((prevLoadedImages) => [...prevLoadedImages, src]);
  };

  const [selected, setSelected] = useState(data[0]);
  const [longitude] = useState("");
  const [latitude] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("1");
  const [selectedCategoryImage, setSelectedCategoryImage] = useState({
    image: [],
    alt: "",
  });
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [isCodeVisible, setIsCodeVisible] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAutoplay] = useState(true);
  const [redImage, setRedImage] = useState(category1_red);
  const [selectedCity, setSelectedCity] = useState("");
  const [storeLocations, setStoreLocations] = useState([]);
  const [cityList] = useState([
    { value: "1", label: "Ahmedabad" },
    { value: "2", label: "Rajkot" },
    { value: "3", label: "Dhoraji" },
    { value: "4", label: "Jetpur" },
    { value: "5", label: "Upleta" },
    { value: "6", label: "Veraval" },
    { value: "7", label: "Morbi" },
    { value: "8", label: "Mangrol" },
    { value: "9", label: "Surat" },
    { value: "10", label: "Baroda" },
    { value: "11", label: "Anand" },
    { value: "12", label: "Una" },
    { value: "13", label: "Porbandar" },
    { value: "14", label: "Amreli" },
    { value: "15", label: "Junagadh" },
    { value: "16", label: "Kodinar" },
    { value: "17", label: "Gandhinagar" },
    { value: "18", label: "Jamnagar" },
    { value: "19", label: "Gondal" },
  ]);

  const sliderRef = useRef(null);

  const handleSelectLocation = (locationDetails) => {};

  const handleImageClick2 = () => {
    sliderRef.current.slickPause(); // Stop autoplay
    setTimeout(() => {
      sliderRef.current.slickPlay(); // Restart autoplay after 2 seconds
    }, 2000);
  };

  useEffect(() => {
    setIsLoaded(false);
  }, [selected.backgroundImage]);

  const handleImageLoad2 = () => {
    setIsLoaded(true);
  };

  // Image error handler (in case loading fails)
  const handleImageError = () => {
    setIsLoaded(false);
  };

  const categories = [
    {
      id: "1",
      images: category1,
      images_red: category1_red,
      title: "Ice Cream",
    },
    {
      id: "2",
      images: category2,
      images_red: category2_red,

      title: "Sundae",
    },
    {
      id: "3",
      images: category3,
      images_red: category3_red,

      title: "M.O.M Shake",
    },
    {
      id: "4",
      images: category4,
      images_red: category4_red,

      title: "Ice Cream Shrikhand",
    },
    {
      id: "5",
      images: category5,
      images_red: category5_red,

      title: "Sizzling Brownie",
    },

    {
      id: "6",
      images: category6,
      images_red: category6_red,

      title: "Stick Bar",
    },
  ];
  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
  });

  const [width, setWidth] = useState(calculateWidth(window.innerWidth));
  var imagesPerSlide = width;

  useEffect(() => {
    const handleResize = () => {
      setWidth(calculateWidth(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function calculateWidth(width) {
    if (width > 350 && width < 500) {
      return 2;
    } else if (width < 350) {
      return 1;
    } else {
      return 3;
    }
  }

  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);

  const prevSlide = () => {
    setDirection(-1);

    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? categories.length - imagesPerSlide : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setDirection(1);

    setCurrentIndex((prevIndex) =>
      prevIndex >= categories.length - imagesPerSlide ? 0 : prevIndex + 1
    );
  };

  const currentImages = categories.slice(
    currentIndex,
    currentIndex + imagesPerSlide
  );

  const categoryimage = [
    {
      categoryId: "1",
      alt: "Ice Cream Images Rangoli Ice Cream",
      images: [
        { image: category1image1 },
        { image: category1image2 },
        { image: category1image3 },
      ],
      subCategory: [
        { title: "Kesar Pista" },
        { title: "Choco Munch" },
        { title: "Silk Chocolate" },
        { title: "Red Velvet" },
        { title: "View More", path: "deliciousdiscovery", categoryId: "1" },
      ],
    },
    {
      categoryId: "2",
      alt: "Sundae Images Rangoli Ice Cream",
      images: [
        { image: category2image1 },
        { image: category2image2 },
        { image: category2image3 },
      ],
      subCategory: [
        { title: "Chocolate Crunch" },
        { title: "Red Velvet" },
        { title: "American Sundae" },
        { title: "Crunchy Kit-Kat" },
        { title: "View More", path: "deliciousdiscovery", categoryId: "2" },
      ],
    },
    {
      categoryId: "3",
      alt: "Mom Shake Images Rangoli Ice Cream",
      images: [
        { image: category3image1 },
        { image: category3image2 },
        { image: category3image3 },
      ],
      subCategory: [
        { title: "Kit-Kat" },
        { title: "Choco Brownie" },
        { title: "Nutty Choco" },
        { title: "Classic Oreo" },
        { title: "View More", path: "deliciousdiscovery", categoryId: "3" },
      ],
    },
    {
      categoryId: "4",
      alt: "Ice Cream Shrikhand Images Rangoli",
      images: [
        { image: category4image1 },
        { image: category4image2 },
        { image: category4image3 },
      ],
      subCategory: [
        { title: "Cream & Bonbon" },
        { title: "Mix Fruit" },
        { title: "Dry Fruit Khajana" },
        { title: "Rajbhog" },
        { title: "View More", path: "deliciousdiscovery", categoryId: "4" },
      ],
    },
    {
      categoryId: "5",
      alt: "Sizzling Brownie Images Rangoli Ice Cream",
      images: [
        { image: category5image1 },
        { image: category5image2 },
        { image: category5image3 },
      ],
      subCategory: [
        { title: "Kit-Kat Sizzling Brownie" },
        { title: "Sizzling Choco Brownie" },
        { title: "Sizzling Brownie" },
        { title: "View More", path: "deliciousdiscovery", categoryId: "5" },
      ],
    },
    {
      categoryId: "6",
      alt: "Stick Bar Images Rangoli Ice Cream",
      images: [
        { image: category6image2 },
        { image: category6image3 },
        { image: category6image1 },
      ],
      subCategory: [
        { title: "Jamun" },
        { title: "Center Burst" },
        { title: "Biscoffee Cone" },
        { title: "Red Velvet Cone" },
        { title: "View More", path: "deliciousdiscovery", categoryId: "6" },
      ],
    },
  ];

  useEffect(() => {
    const filteredImages = categoryimage.filter(
      (item) => item.categoryId === selectedCategory
    );
    // Update the state based on the count
    if (filteredImages[0].images.length > 0) {
      setSelectedCategoryImage({
        image: filteredImages[0].images,
        alt: filteredImages[0].alt,
      });
      setSelectedSubCategories(filteredImages[0].subCategory);
    } else {
      setSelectedCategoryImage([]); // Clear the state if no images match
    }
  }, [selectedCategory]);

  useEffect(() => {
    setSelectedCategory("1");
    setRedImage(categories[0].images_red);
    setIsCodeVisible(true);
  }, []);

  const handleNameClick = (item) => {
    setSelected(item);
  };

  // const radius = 35; // Percentage of the viewport width for responsiveness
  const [radius, setRadius] = useState(calculateRadius(window.innerWidth));

  useEffect(() => {
    const handleResize = () => {
      setRadius(calculateRadius(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function calculateRadius(width) {
    if (width < 421) {
      return 43;
    } else if (width < 576) {
      return 42;
    } else if (width < 721) {
      return 42;
    } else if (width < 768) {
      return 42;
    } else if (width < 775) {
      return 42;
    } else if (width < 882) {
      return 39;
    } else if (width < 1200) {
      return 36;
    } else {
      return 35;
    }
  }
  const newArrivalImages = [
    { image: newarrivalimage6, alt: "Oreo Ice Cream Images Rangoli Ice Cream" },
    { image:newarrivalimage7, alt: "Kathiya wadi Kulfi Ice cream  Images Rangoli" },
    { image:newarrivalimage1, alt: "Strawberry Ice Cream Images Rangoli Ice Cream" },
    {image: newarrivalimage3, alt: "Biscoff Ice Cream Images Rangoli" },
    {image: newarrivalimage5, alt: "Sitafal Ice Cream Images Rangoli" },

    // Add more image URLs as needed
  ];

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      borderRadius: "10px", // Add border-radius to the control (input area)
      padding: "5px 5px", // Add padding to each dropdown option
      color: "gray",
      fontSize: "16px",
      fontFamily: "gilroy semibold", // Apply Gilroy SemiBold font
      fontWeight: 100, // Ensure SemiBold weight is applied
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: "10px", // Add border-radius to the control (input area)
      padding: "5px 5px", // Add padding to each dropdown option
      boxShadow: "none",
      fontFamily: "gilroy semibold", // Apply Gilroy font to the control
      fontWeight: 100,
      "&:hover": {
        boxShadow: "1px 1px 5px white",
      },
      borderColor: "white",
    }),
    indicatorSeparator: () => ({
      display: "none", // Remove the vertical line next to the dropdown icon
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000", // Customize the color of the selected value
      fontFamily: "gilroy semibold", // Apply Gilroy font to the selected value
      fontWeight: 100,
    }),
    menu: (provided) => ({
      ...provided,
      fontFamily: "gilroy semibold", // Apply Gilroy font to the menu options
      fontWeight: 100,
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#fff" : "#333", // Different color when selected
      backgroundColor: state.isSelected ? "#007bff" : "#fff",
      fontFamily: "", // Apply Gilroy font to options
      fontWeight: 100,
      "&:hover": {
        backgroundColor: "#80bdff", // Background on hover
        color: "#fff",
      },
    }),
  };

  // "homepage": "https://rangoliicecream.com/",
  // <Router basename={"/"} >

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const variants = {
    hidden: { opacity: 0, y: -50 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.2, type: "spring", stiffness: 50 },
    }),
  };
  const variants2 = {
    hidden: {
      opacity: 0,
      x: screenSize < 401 ? -50 : screenSize < 739 ? -100 : -200,
    },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.2,
        type: "spring",
        stiffness: 50,
        damping: 20,
      },
    }),
  };
  const variants4 = {
    hidden: { opacity: 0, x: 200 },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.1,
        type: "spring",
        stiffness: 50,
        damping: 20,
      },
    }),
  };

  const leftSide = {
    hidden: {
      opacity: 0,
      x: screenSize < 401 ? -50 : screenSize < 739 ? -100 : -200,
    },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.1,
        type: "spring",
        stiffness: 50,
        damping: 20,
      },
    }),
  };
  const rightSide = {
    hidden: {
      opacity: 0,
      x: screenSize < 401 ? 50 : screenSize < 739 ? 100 : 200,
    },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.1,
        type: "spring",
        stiffness: 50,
        damping: 20,
      },
    }),
  };
  const variants5 = {
    hidden: { opacity: 0, y: 200 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.1,
        type: "spring",
        stiffness: 50,
        damping: 20,
      },
    }),
  };

  const variants3 = {
    hidden: { opacity: 0, scale: 0.8 }, // Start scaled down
    visible: (i) => ({
      opacity: 1,
      scale: 1, // Scale to original size
      transition: {
        type: "spring",
        stiffness: 50,
        opacity: { duration: 0.1 }, // Fade-in duration
        scale: { type: "spring", stiffness: 50, damping: 10 },
      },
    }),
    exit: {
      opacity: 0,
      scale: 0.8, // Scale down when exiting
      transition: {
        opacity: { duration: 0.1 }, // Fade-out duration
        scale: { type: "spring", stiffness: 50, damping: 10 },
      },
    },
  };

  const transformStyle = (x, y, angle, title) => {
    const rotationAngle = title === "M.O.M Shake" ? 98 : 95;

    return `translate(${x}vw, ${y}vw) rotate(${angle + rotationAngle}deg)`;
  };

  const breakText = (text) => {
    // Check if the text is either "Ice Cream Shrikhand" or "Sizzling Brownie"
    if (text === "Ice Cream Shrikhand" || text === "Sizzling Brownie") {
      const words = text.split(" ");
      const lastWord = words.pop(); // Get the last word (Shrikhand or Brownie)
      const restOfText = words.join(" "); // Join the remaining words

      return (
        <>
          <div
            style={{
              lineHeight: "100%",
              marginTop:
                screenSize < 551
                  ? "-1rem"
                  : screenSize < 577
                  ? "-0.7rem"
                  : screenSize < 847
                  ? "-1rem"
                  : "",
            }}
          >
            <span style={{ display: "block" }}>{restOfText}</span>

            <span
              style={{
                display: "block",
              }}
            >
              {lastWord}
            </span>
          </div>
        </>
      );
    }

    return text;
  };

  const settings = {
    slidesPerRow: 1,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    swipeToSlide: true,
    autoplay: isAutoplay,
    autoplaySpeed: 2000, // Set the speed (in milliseconds)
    responsive: [
      {
        breakpoint: 1453,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1127,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 743,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const animationVariants = {
    visible: { opacity: 1, scale: 1, y: 0 },
    hidden: { opacity: 0, scale: 0.5, y: 100 },
  };

  const locationListApiCall = async () => {
    const params = {
      cityId: selectedCity.value,
    };

    try {
      const response = await postData(
        "/locationlist",
        params,
        apiHeader(false)
      );

      if (String(response?.status) === "200") {
        const data = response.data.data;

        // Convert and store locations in lat, lng format
        const formattedLocations = data.map((location) => ({
          lat: location.latitude,
          lng: location.longitude,
        }));

        setStoreLocations(formattedLocations);
      } else {
        toasterrormsg(response.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (
      selectedCity != "" &&
      selectedCity != undefined &&
      selectedCity != null
    ) {
      locationListApiCall();
    }
  }, [selectedCity]);

  useEffect(() => {
    document.title = "Rangoli Ice Cream || Home";
  }, []);

  return (
    <>
      <div
        style={{ position: "fixed", bottom: "70px", right: 0, zIndex: 1012120 }}
      >
        <span className="float">
          <a
            href="https://api.whatsapp.com/send?phone=919512231112"
            title="whatsapp"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://cdn2.iconfinder.com/data/icons/simple-social-media-shadow/512/3-512.png"
              alt="Whatsapp ICon"
              width="100px"
            />
          </a>
        </span>
      </div>
      <div className="row mt-lg-n20">
        <div className="col-lg-12 text-center">
          <p className="shiny-text">रंग जमा दे रंगोली...</p>

          {/* <img
            src={toAbsoluteUrl("media/home_banner/hinditext.png")}
            alt="Hindi text Rang Jama de rangoli"
            className="mb-5  pl-2 pr-2 hindi_text"
          />
           */}
          {/* <LazyLoadImage
            className="mb-5  pl-2 pr-2 hindi_text img-lazy"
            src={hindiText}
            alt={"Rang Jama De rangoli written in hindi"}
            effect="blur"
          /> */}

          <h1 className="joy_text">We Make People Joyful by Sharing Joy</h1>
        </div>
      </div>

      <div className="container-fluid pb-4">
        <div className="row">
          <div className="col-lg-12">
            <motion.h2
              className="text-center banner_title"
              key={selected.title}
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.1 }}
              style={{ color: selected.activeColor }}
            >
              <AnimatedText text={selected.title} />
            </motion.h2>
            <div className="d-flex justify-content-center">
              <div className="main_half_circle">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    left:
                      selected.title === "ICE CREAM"
                        ? screenSize < 775
                          ? "-0.7%"
                          : screenSize < 882
                          ? "-1%"
                          : screenSize < 1200
                          ? "-0.4%"
                          : screenSize < 1300
                          ? "0.1%"
                          : "0.6%"
                        : selected.title === "SUNDAE"
                        ? screenSize < 465
                          ? "5%"
                          : screenSize < 882
                          ? "4%"
                          : screenSize < 1200
                          ? "4.5%"
                          : screenSize < 1300
                          ? "5.7%"
                          : "6.5%"
                        : selected.title === "M.O.M Shake"
                        ? screenSize < 600
                          ? "19%"
                          : screenSize < 882
                          ? "14%"
                          : screenSize < 1200
                          ? "15%"
                          : screenSize < 1300
                          ? "16%"
                          : "19%"
                        : selected.title === "ICE CREAM SHRIKHAND"
                        ? screenSize < 551
                          ? "82%"
                          : screenSize < 775
                          ? "80.7%"
                          : screenSize < 882
                          ? "80%"
                          : "80%"
                        : selected.title === "SIZZLING BROWNIE"
                        ? screenSize < 600
                          ? "92%"
                          : screenSize < 882
                          ? "93%"
                          : "91%"
                        : selected.title === "STICK BAR"
                        ? screenSize < 600
                          ? "98%"
                          : screenSize < 775
                          ? "98.6%"
                          : "98%"
                        : "",
                    top:
                      selected.title === "ICE CREAM"
                        ? screenSize < 313
                          ? "58%"
                          : screenSize < 375
                          ? "56%"
                          : screenSize < 465
                          ? "59%"
                          : screenSize < 600
                          ? "62%"
                          : screenSize < 775
                          ? "64%"
                          : screenSize < 882
                          ? "69%"
                          : screenSize < 1200
                          ? "72.5%"
                          : "75%"
                        : selected.title === "SUNDAE"
                        ? screenSize < 313
                          ? "36%"
                          : screenSize < 375
                          ? "34.6%"
                          : screenSize < 465
                          ? "35%"
                          : screenSize < 600
                          ? "39%"
                          : screenSize < 775
                          ? "41%"
                          : screenSize < 882
                          ? "43%"
                          : "46%"
                        : selected.title === "M.O.M Shake"
                        ? screenSize < 465
                          ? "12%"
                          : screenSize < 600
                          ? "13.4%"
                          : screenSize < 775
                          ? "19.7%"
                          : "18.4%"
                        : selected.title === "ICE CREAM SHRIKHAND"
                        ? screenSize < 551
                          ? "17%"
                          : screenSize < 775
                          ? "15%"
                          : screenSize < 882
                          ? "16%"
                          : screenSize < 1200
                          ? "17%"
                          : "20%"
                        : selected.title === "SIZZLING BROWNIE"
                        ? screenSize < 551
                          ? "37%"
                          : screenSize < 600
                          ? "35%"
                          : screenSize < 775
                          ? "37%"
                          : screenSize < 882
                          ? "41%"
                          : screenSize < 1200
                          ? "44%"
                          : "43%"
                        : selected.title === "STICK BAR"
                        ? screenSize < 600
                          ? "61%"
                          : screenSize < 775
                          ? "63%"
                          : screenSize < 882
                          ? "68%"
                          : screenSize < 1200
                          ? "72%"
                          : "74.6%"
                        : "",
                  }}
                  transition={{
                    duration: 0, // adjust duration to control the speed of the transition
                    ease: "easeInOut", // you can experiment with different easing functions
                  }}
                  key={selected.title}
                  style={{
                    position: "absolute",
                    width:
                      screenSize < 375
                        ? "8px"
                        : screenSize < 775
                        ? "12px"
                        : screenSize < 1200
                        ? "18px"
                        : "22.16px",
                    height:
                      screenSize < 375
                        ? "8px"
                        : screenSize < 375
                        ? "10px"
                        : screenSize < 775
                        ? "12px"
                        : screenSize < 1200
                        ? "18px"
                        : "22.16px",
                    borderRadius: "50%",
                    backgroundColor: selected.activeColor,
                  }}
                ></motion.div>
                <div className="half_circle">
                  <div className="image-title">
                    {console.log(selected.backgroundImage)}
                    <LazyLoadImage
                      className="banner_background cursor-pointer img-lazy"
                      src={
                        isLoaded == true
                          ? selected.backgroundImage
                          : selected.blur
                      }
                      key={selected.backgroundImage}
                      alt={selected.alt}
                      onLoad={handleImageLoad2}
                      onError={handleImageError}
                      effect="blur"
                      wrapperProps={{
                        style: {
                          transitionDelay: isLoaded ? "1s" : "0s", // Set transition delay conditionally
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="names">
              {data.map((item, index) => {
                const angle = 185 + index * (160 / (data.length - 1)); // Adjusted angle calculation for half-circle
                const x = radius * Math.cos(angle * (Math.PI / 180));
                const y = radius * Math.sin(angle * (Math.PI / 180));

                return (
                  <>
                    <motion.div
                      key={index}
                      className="name"
                      style={{
                        transform: transformStyle(x, y, angle, item.title),
                        color:
                          selected.name === item.name
                            ? item.activeColor
                            : "lightgray",
                      }}
                      onClick={() => handleNameClick(item)}
                      whileHover={{ color: item.activeColor }}
                    >
                      {breakText(item.name)}
                    </motion.div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* d-md-none */}
      <div className="arrival_section" style={{ position: "relative" }}>
        {/* <img
          className="arrival_banner bigarrivalbanner"
          alt="images"
          src={new_arrival_banner}
        /> */}
        <div
          className="arrival_banner bigarrivalbanner"
          alt="Rangoli ice Cream Background"
        ></div>
        <div
          className="arrival_content pt-5"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: "10000",
          }}
        >
          <div>
            <div className="d-flex  justify-content-between align-items-center">
              <div className="d-flex justify-content-start">
                <motion.img
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={leftSide}
                  src={sideInnerLeft}
                  alt="Rangoli Ice Cream Left Side bars"
                  className="side_lines"
                />
              </div>
              <motion.h2
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={animationVariants}
                className="text-center new_arrival_title pt-5"
              >
                New Arrival
              </motion.h2>
              <div className="d-flex justify-content-end">
                <motion.img
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={rightSide}
                  src={sideInnerRight}
                  alt="Rangoli Ice Cream Right Side bars"
                  className="side_lines"
                />
              </div>
            </div>

            <div className="text-center">
              <motion.img
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{
                  duration: 0.1,
                  type: "spring",
                  stiffness: 200,
                  damping: 10,
                }}
                variants={{
                  visible: { opacity: 1, scale: 1, y: 0 },
                  hidden: { opacity: 0, scale: 0.5, y: 100 },
                }}
                className="text-image pt-4"
                src={divider}
                alt="Title Designer Divider"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>

            <div className="new_arrival_images">
              <div className="d-flex justify-content-evenly align-items-center flex-wrap gap-4">
                {[
                  {
                    src: newarrivalimage6,
                    alt: "Oreo Ice Cream Images Rangoli Ice Cream",
                  },
                  {
                    src: newarrivalimage7,
                    alt: "Kathiya wadi Kulfi Ice cream  Images Rangoli",
                  },
                  {
                    src: newarrivalimage1,
                    alt: "Strawberry Ice Cream Images Rangoli Ice Cream",
                  },

                  {
                    src: newarrivalimage3,
                    alt: "Biscoff Ice Cream Images Rangoli",
                  },

                  {
                    src: newarrivalimage5,
                    alt: "Sitafal Ice Cream Images Rangoli",
                  },
                ].map((item, index) => (
                  <div className="image-wrapper" key={index}>
                    <motion.img
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                      transition={{
                        duration: 0.4 + index * 0.2,
                        type: "spring",
                        stiffness: 200,
                        damping: 10,
                      }}
                      variants={{
                        visible: { opacity: 1, scale: 1, y: 0 },
                        hidden: { opacity: 0, scale: 0.5, y: 100 },
                      }}
                      whileHover={{
                        scale: 1.1,
                      }}
                      style={{
                        marginTop:
                          (screenSize > 821 && index == 1) ||
                          (screenSize > 821 && index == 3)
                            ? "11rem"
                            : "",
                      }}
                      whileTap={{ scale: 1.1 }} // Slight zoom on tap
                      src={item.src}
                      alt={item.alt}
                      className="color-toggle-image image-with-overlay"
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="new_arrival_carousel">
              <div className="mt-5 d-flex justify-content-center">
                <Carousel images={newArrivalImages} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="arrival_below">
        <motion.h2
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={animationVariants}
          whileHover="hover"
          className="text-center new_arrival_title"
        >
          Delicious Discovery
        </motion.h2>
        <div className="text-center">
          <motion.img
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{
              duration: 0.1,
              type: "spring",
              stiffness: 200,
              damping: 10,
            }}
            variants={{
              visible: { opacity: 1, scale: 1, y: 0 },
              hidden: { opacity: 0, scale: 0.5, y: 100 },
            }}
            className="text-image pt-4"
            src={divider}
            alt="images"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>

        <div {...handlers} className="category_container category_slider">
          <div className="category_carousel">
            <AnimatePresence initial={false} custom={direction}>
              {currentImages.map((item, index) => {
                const isSelected = selectedCategory === item.id;
                return (
                  <motion.div
                    custom={index}
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    className={`text-center ${isSelected ? "selected" : ""}`}
                    onClick={() => {
                      setSelectedCategory(item.id);
                      setIsCodeVisible(true);
                      setRedImage(item.images_red);
                    }}
                    style={{
                      borderRadius: "8px",
                      width: "14rem",
                      paddingTop: "1rem",
                    }}
                  >
                    {isSelected & isCodeVisible ? (
                      <motion.div
                        custom={index}
                        transition={{
                          type: "spring",
                          stiffness: 400,
                          damping: 10,
                        }}
                        initial="hidden"
                        animate="visible"
                        variants={variants3}
                      >
                        <img
                          src={redImage}
                          alt="category"
                          className="category_image"
                          style={{
                            width: screenSize < 425 ? "2.8rem" : "",
                            height: screenSize < 425 ? "2.8rem" : "",
                          }}
                        />
                      </motion.div>
                    ) : (
                      <img
                        src={item.images}
                        alt="category"
                        className="category_image"
                        style={{
                          width: screenSize < 425 ? "2.8rem" : "",
                          height: screenSize < 425 ? "2.8rem" : "",
                        }}
                      />
                    )}
                    {isSelected & isCodeVisible ? (
                      <motion.div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{
                          type: "spring",
                          stiffness: 400,
                          damping: 10,
                        }}
                        initial="hidden"
                        animate="visible"
                        variants={variants3}
                        style={{
                          color: "#e32024",
                          fontSize: screenSize < 425 ? "18px" : "",
                        }}
                        className="category_title"
                      >
                        {item.title}
                      </motion.div>
                    ) : (
                      <div
                        className="category_title"
                        style={{
                          fontSize: screenSize < 425 ? "18px" : "",
                        }}
                      >
                        {item.title}
                      </div>
                    )}
                  </motion.div>
                );
              })}
            </AnimatePresence>
          </div>
        </div>

        <div className="category_slider_buttons category_slider">
          <button
            className="category_slider_button next_button_category"
            onClick={nextSlide}
          >
            <FontAwesomeIcon
              icon={faGreaterThan}
              style={{
                transform: "rotate(180deg)", // Rotates the icon 180 degrees
                fontSize: "12px", // Adjust the size to make it smaller
              }}
            />
          </button>
          <button
            className="category_slider_button prev_button_category"
            onClick={prevSlide}
          >
            <FontAwesomeIcon icon={faGreaterThan}></FontAwesomeIcon>
          </button>
        </div>

        <div className="delicious_images mt-5 ">
          <div className="tab_section d-flex justify-content-center gap-4 align-items-center ">
            {categories.map((item, index) => {
              const isSelected = selectedCategory === item.id;

              return (
                <AnimatePresence>
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    custom={index}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    variants={variants}
                    key={index}
                    className={`text-center ${
                      isSelected ? "selected" : ""
                    } cursor-pointer`}
                    onClick={() => {
                      if (!isSelected) {
                        setSelectedCategory(item.id);
                        setIsCodeVisible(true);
                        setRedImage(item.images_red);

                        item.images = "";
                      }
                    }}
                    style={{
                      borderRadius: "8px",
                      width: "14rem",
                      paddingTop: "1rem",
                    }}
                  >
                    {isSelected & isCodeVisible ? (
                      <motion.div
                        custom={index}
                        transition={{
                          type: "spring",
                          stiffness: 400,
                          damping: 10,
                        }}
                        initial="hidden"
                        animate="visible"
                        variants={variants3}
                      >
                        <img
                          src={redImage}
                          alt="category"
                          className=""
                        />
                      </motion.div>
                    ) : (
                      <img
                        src={item.images}
                        alt="category"
                        className={"Rangoli "+item.title +" Image"}
                      />
                    )}
                    {isSelected & isCodeVisible ? (
                      <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={variants3}
                        transition={{
                          type: "spring",
                          stiffness: 400,
                          damping: 10,
                        }}
                        style={{
                          color: "#e32024",
                        }}
                        className="category_title"
                      >
                        {item.title}
                      </motion.div>
                    ) : (
                      <div className="category_title ">{item.title}</div>
                    )}
                  </motion.div>
                </AnimatePresence>
              );
            })}
          </div>
        </div>

        <div className="mt-5 position-relative side_bar_section">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={variants2}
            className="position-relative"
          >
            <div className="homeDeliciousImagesMain">
              {selectedCategoryImage.image &&
              selectedCategoryImage.image.length > 0
                ? selectedCategoryImage.image.map((item, index) => {
                    const isLoaded = loadedImages.includes(item.image);
                    return (
                      <AnimatePresence key={item.image}>
                        <motion.div className="image-container">
                          <motion.img
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            alt={selectedCategoryImage.alt}
                            custom={index}
                            onLoad={() => handleImageLoad(item.image)}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{
                              type: "spring",
                              stiffness: 400,
                              damping: 10,
                            }}
                            variants={variants}
                            className={`homeDeliciousImages  ${
                              isLoaded ? "visible" : "hidden"
                            }`}
                            src={item.image}
                          />
                        </motion.div>
                      </AnimatePresence>
                    );
                  })
                : ""}
            </div>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={variants4}
            className="side_bars"
          >
            {selectedSubCategories != 0 && selectedSubCategories.length > 0 ? (
              selectedSubCategories.map((item, index) => (
                <div className="side_bar_inner" key={index}>
                  <motion.div
                    key={item.title}
                    custom={index}
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${subcategory_background})`,
                      }}
                      className="side_bar_background"
                    >
                      <div className="sidebar_subcategory_name">
                        {item.title == "View More" ? (
                          <>
                            <li>
                              <Link
                                style={{ color: "#6b3d2b" }}
                                to={item.path}
                                state={{ categoryId: item.categoryId }}
                                className="text-decoration-none"
                              >
                                {item.title}
                              </Link>
                            </li>
                          </>
                        ) : (
                          <li>{item.title}</li>
                        )}
                      </div>
                    </div>
                  </motion.div>
                </div>
              ))
            ) : (
              <div className="side_bar_inner">
                <motion.div
                  key={selectedCategory}
                  initial="hidden"
                  animate="visible"
                  variants={variants}
                  style={{
                    backgroundImage: `url(${subcategory_background})`,
                  }}
                  className="side_bar_background"
                >
                  <div className="sidebar_subcategory_name">
                    <li>No subcategories</li>
                  </div>
                </motion.div>
              </div>
            )}
          </motion.div>
        </div>
      </div>

      <div className="search_section">
        <div className="d-flex  justify-content-between align-items-center">
          <div className="d-flex justify-content-start">
            <motion.img
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={leftSide}
              src={sideInnerLeft}
              alt="category"
              className="side_lines"
            />
          </div>
          <motion.h2
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={animationVariants}
            className="text-center new_arrival_title pt-5"
          >
            Rangoli Near You
          </motion.h2>
          <div className="d-flex justify-content-end">
            <motion.img
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={rightSide}
              src={sideInnerRight}
              alt="category"
              className="side_lines"
            />
          </div>
        </div>
        <div className="text-center">
          <motion.img
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{
              duration: 0.1,
              type: "spring",
              stiffness: 200,
              damping: 10,
            }}
            variants={{
              visible: { opacity: 1, scale: 1, y: 0 },
              hidden: { opacity: 0, scale: 0.5, y: 100 },
            }}
            className="text-image pb-3 pt-3"
            src={divider}
            alt="images"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>

        <div className="container">
          <div className="row search-select">
            {/* <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={variants2}
              className="col-lg-6 col-md-6 col-sm-12 search_select_first"
            >
              <Select
                name="selectState"
                id="selectState"
                placeholder="Select State"
                value={selectedState}
                options={stateList}
                isSearchable={true}
                styles={customStyles} // Apply custom styles
                onChange={(e) => {
                  setSelectedState(e);
                }}
              />
            </motion.div> */}
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={variants4}
              className="col-lg-12 col-md-6 col-sm-12"
            >
              <Select
                name="cityList"
                id="cityList"
                placeholder="Select City"
                isSearchable={true}
                options={cityList}
                value={selectedCity}
                styles={customStyles} // Apply custom styles
                onChange={(e) => {
                  setSelectedCity(e);
                }}
              />
            </motion.div>
          </div>
        </div>
        <div className="map-section">
          <div className=" d-flex justify-content-center ">
            <motion.div className="map">
              <GoogleMapComponent
                latitude={latitude}
                longitude={longitude}
                height={"288px"}
                onSelectLocation={handleSelectLocation}
                initialMarkers={storeLocations}
              />
            </motion.div>
          </div>
        </div>
      </div>

      <div className="journey_section">
        <motion.h2
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={animationVariants}
          className="text-center new_arrival_title"
        >
          Our joyride Over The Years
        </motion.h2>
        <div className="text-center">
          <motion.img
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{
              duration: 0.1,
              type: "spring",
              stiffness: 200,
              damping: 10,
            }}
            variants={{
              visible: { opacity: 1, scale: 1, y: 0 },
              hidden: { opacity: 0, scale: 0.5, y: 100 },
            }}
            className="text-image pt-4"
            src={divider}
            alt="images"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>

        <div className="d-flex justify-content-evenly journey-three p-5">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={variants5}
          >
            <img
              src={journey_image}
              alt="Rangoli Ice Cream Journey"
              className="journeyimage2 w-100"
            />
          </motion.div>
        </div>
      </div>

      <div className="connect_section p-1">
        <div>
          <motion.h2
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={animationVariants}
            whileHover="hover"
            className="text-center new_arrival_title"
          >
            Connect With Us
          </motion.h2>
          <div className="text-center">
            <motion.img
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{
                duration: 0.1,
                type: "spring",
                stiffness: 200,
                damping: 10,
              }}
              variants={{
                visible: { opacity: 1, scale: 1, y: 0 },
                hidden: { opacity: 0, scale: 0.5, y: 100 },
              }}
              className="text-image pt-4"
              src={divider}
              alt="images"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>

          <div className="instagram_section d-flex justify-content-center align-items-center  mt-2">
            <Link
              to={
                "https://www.instagram.com/rangoliicecream.official?igsh=ZnIyMnNoeGdkcWhw"
              }
              target="_blank"
              rel="noreferrer"
              className="text-decoration-none  d-flex justify-content-center align-items-center "
            >
              <div className="d-flex justify-content-center align-items-center">
                <motion.img
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={variants2}
                  whileTap={{ scale: 0.9 }}
                  className="instagram-image cursor-pointer"
                  src={anotherImage}
                  alt="instagram logo"
                />
              </div>

              <div className="d-flex justify-content-center align-items-center">
                <motion.p
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={animationVariants}
                  whileHover="hover"
                  className="text-center instagram_title cursor-pointer"
                >
                  RANGOLIICECREAM.OFFICIAL
                </motion.p>
              </div>
            </Link>
          </div>
        </div>

        <Slider ref={sliderRef} {...settings} className="connect-image mt-5">
          {[...Array(5)].map((_, index) => (
            <motion.div
              key={index}
              onClick={handleImageClick2}
              className="cursor-pointer d-flex justify-content-center"
            >
              <img
                src={toAbsoluteUrl(`media/connectwithus/image${index + 1}.webp`)}
                alt="Rangoli Ice Cream Images"
              />
            </motion.div>
          ))}
        </Slider>
      </div>
      <Footer />
    </>
  );
};

const Home = () => {
  return <Header element={<HomeWrapper />} />;
};

export { Home };
